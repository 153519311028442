import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import {
  DiCss3,
  DiJsBadge,
  DiReact,
  DiHtml5,
  DiGit,
  DiNodejs,
  DiFirebase,
  DiAws,
} from 'react-icons/di';
import { motion } from 'framer-motion';

const StackBackground = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section>
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <div className="floating-container">
            <motion.div
              className="icon"
              animate={{ y: [0, -15, 0, 15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiReact size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, 15, 0, -15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiJsBadge size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, -15, 0, 15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiNodejs size={70} />
            </motion.div>

            <motion.div
              className="icon"
              animate={{ y: [0, 15, 0, -15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiGit size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, -15, 0, 15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiCss3 size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, 15, 0, -15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiHtml5 size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, -15, 0, 15, 0] }}
              transition={{ duration: 3, loop: Infinity, ease: 'linear' }}
            >
              <DiFirebase size={70} />
            </motion.div>
            <motion.div
              className="icon"
              animate={{ y: [0, 15, 0, -15, 0] }}
              transition={{
                type: 'tween',
                duration: 3,
                loop: Infinity,
                ease: 'linear',
              }}
            >
              <DiAws size={70} />
            </motion.div>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default StackBackground;
